import Repository from "./Repository";

const ADD_ENTRY = "addstate";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "getstate";

export default {
  addState(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editState(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteState(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getState(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/state/${route}`;
};
