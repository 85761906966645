import Repository from "./Repository2";
import Repoistory2 from "./Repository3";
// import LocalRepository from "./LocalRepository";

const getClientCms = "cms/getClientCms";
const getUser = "users/findAllUser";
const updateClientCms = "cms/updateClientCms";
const uploadImage = "upload/helper";

export default {
	getClientCms(payload) {
		return Repository.post(`${getClientCms}`, payload);
	},
	updateClientCms(payload) {
		return Repository.post(`${updateClientCms}`, payload);
	},
	getUser(payload) {
		return Repoistory2.post(`${getUser}`, payload);
	},
	uploadImage(payload) {
		return Repository.post(`${uploadImage}`, payload);
	},
};
