// import RepositoryFactory from "@/apis";
import EuroReward from "@/apis/EuroReward";
import API from "@/apis/Repository";
import { reject } from "lodash";
import RepositoryFactory from "../../apis";

const contactDetail = RepositoryFactory.contactDetail;
const GetInTouch = RepositoryFactory.GetInTouch;
const banner = RepositoryFactory.Banner;
const euroReward = RepositoryFactory.EuroReward;
const DesignSupport = RepositoryFactory.DesignSupport;
const Careers = RepositoryFactory.Careers;
const contactUs = RepositoryFactory.ContactUs;
const ProjectCategory = RepositoryFactory.ProjectCategory;
const catalogue = RepositoryFactory.catalogue;
const documents = RepositoryFactory.document;
const subcategory = RepositoryFactory.subcategory;
const tax = RepositoryFactory.Tax;
const product = RepositoryFactory.product;
const productcategory = RepositoryFactory.productCategory;
const productSubCategory = RepositoryFactory.productSubCategory;
const sizeVariant = RepositoryFactory.sizeVariant;
const colorVariant = RepositoryFactory.colorVariant;
const cart = RepositoryFactory.cart;
const order = RepositoryFactory.order;
const coupon = RepositoryFactory.coupon;
const dispatch = RepositoryFactory.dispatch;
const address = RepositoryFactory.address;
const BlogCategory = RepositoryFactory.blogCategory;
const news = RepositoryFactory.news;
const Event = RepositoryFactory.Event;
const EventRegister = RepositoryFactory.EventRegister;
const Upcomingevent = RepositoryFactory.upcomingevent;
const Enquire = RepositoryFactory.enquire;
const Project = RepositoryFactory.Project;
const Blog = RepositoryFactory.Blog;
const Country = RepositoryFactory.Country;
const State = RepositoryFactory.State;
const City = RepositoryFactory.City;
const Subscriber = RepositoryFactory.Subscribe;
export default {
  addSubscriber({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Subscriber.add(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSubscriber({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Subscriber.get(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addGetInTouch({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await GetInTouch.add(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    COUNTRYS   -------------------
  // ############################################################
  getCity({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await City.getCity(payload);
        // (data);
        commit("GET_CITY", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addCity({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await City.addCity(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteCity({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await City.deleteCity(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editCity({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await City.editCity(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    COUNTRYS   -------------------
  // ############################################################
  getState({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await State.getState(payload);
        // (data);
        commit("GET_STATE", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addState({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await State.addState(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteState({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await State.deleteState(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editState({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await State.editState(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    COUNTRYS   -------------------
  // ############################################################
  getCountry({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Country.getCountry(payload);
        // (data);
        commit("GET_COUNTRY", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addCountry({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Country.addCountry(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteCountry({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Country.deleteCountry(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editCountry({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Country.editCountry(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    BLOGS   -------------------
  // ############################################################
  getBlogCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await BlogCategory.getCategories(payload);
        // (data);
        commit("GET_BLOG_CATEGORY", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addBlogCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await BlogCategory.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteBlogCategory({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await BlogCategory.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editBlogCategory({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await BlogCategory.editCategory(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    BLOGS   -------------------
  // ############################################################
  getBlogs({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Blog.getBLog(payload);
        // (data);
        commit("GET_BLOGS", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleBLog({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Blog.getSingleBLog(payload);
        // (data);
        commit("GET_SINGLE_BLOG", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addBlogs({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Blog.addBlog(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteBlogs({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Blog.deleteBlog(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editBlogs({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Blog.editBlog(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ############################################################
  // -------------------    PROJECT   -------------------
  // ############################################################
  getProject({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Project.getProject(payload);
        // (data);
        commit("GET_PROJECTS", data.data);
        // console.log(data.data, "projectdata");
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleProject({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Project.getSingleProject(payload);
        // (data);
        commit("GET_SINGLE_PROJECTS", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addProject({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Project.addProject(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteProject({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Project.deleteProject(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editProject({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Project.editProject(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  uploadProjectImage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Project.uploadImage(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addProductDetail({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Project.addproductdetails(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    PROJECT CATEGORY  -------------------
  // ############################################################
  getProjectCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await ProjectCategory.getCategories(payload);
        // (data);
        commit("GET_PROJECT_CATEGORY", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addProjectCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await ProjectCategory.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteProjectCategory({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await ProjectCategory.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editProjectCategory({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await ProjectCategory.editCategory(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // -----------------  OTHERS -------------------------
  // ################################################################

  UPDATE_singleProductAdminStatee({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("UPDATE_singleProductAdminState", payload);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // -----------------  ContactUs -------------------------
  // ################################################################

  ContactUs({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await contactUs.add(payload);
        commit("CONTACT_US", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // -----------------  Upcoming Event -------------------------
  // ################################################################
  getupcomingEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Upcomingevent.getupcomingEvent(payload);

        commit("GET_EVENT", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  addupcomingEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Upcomingevent.addupcomingEvent(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  upcomingeventDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Upcomingevent.deleteupcomingEvent(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editupcomingEvent({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Upcomingevent.editupcomingEvent(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // -----------------  Event -------------------------
  // ################################################################
  getEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Event.getEvent(payload);

        commit("GET_EVENT", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getEventSingle({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Event.getEventSingle(payload);

        commit("GET_EVENT_SINGLE", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  RegisterEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await EventRegister.add(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Event.addEvent(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  uploadEventImage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Event.uploadImage(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  eventDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Event.deleteEvent(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editEvent({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Event.editEvent(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // -----------------  NEWS -------------------------
  // ################################################################
  getNews({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await news.getNews(payload);

        commit("GET_NEWS", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  addNews({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await news.addNews(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  newsDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await news.deleteNews(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editNews({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await news.editNews(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // -----------------  CONTACT DETAILS -------------------------
  // ################################################################
  getContact({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await contactDetail.get(payload);

        commit("GET_CONTACT", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  contactAddCollection({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await contactDetail.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  contactDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await contactDetail.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editContact({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await contactDetail.edit(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // -----------------  CATEGORY -------------------------
  // ################################################################
  getCatalogue({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await catalogue.getCategories(payload);

        commit("GET_CATALOGUE", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getManyCatalogue({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await catalogue.getMany(payload);

        commit("GET_MANY_CATALOGUE", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  catalogueAddCollection({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await catalogue.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  catalogueDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await catalogue.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editCatalogue({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await catalogue.editCategory(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ################################################################
  // -------------------    SUBCATEGORY  -----------------------------
  // ################################################################
  getSubCategories({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.getSubcategory(payload);
        commit("GET_SUBCATEGORY_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getSubCategoriesById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.getSubcategoryById(payload);
        commit("SELECT_SUBCATEGORY_BY_ID", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  subCategoryAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  subCategoryEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.updateCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  subCategoryDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ################################################################
  // -------------------    DOCUMENTS  -----------------------------
  // ################################################################
  getDocument({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await documents.getDocument(payload);
        commit("GET_DOCUMENTS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  ById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await documents.getDocument(payload);
        commit("SELECT_SUBCATEGORY_BY_ID", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  addDocument({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await documents.addDocument(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editDocument({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await documents.editDocument(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  downloadFile({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await documents.downloadfile(payload);
        commit("DOWNLOAD_DOCUMENT", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteDocument({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await documents.deleteDocument(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ############################################################
  // -------------------    TAXES  -----------------------------
  // ############################################################
  getTaxes({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await tax.getAllCollections();
        commit("UPDATE_TAXES_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  taxAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await tax.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  taxEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await tax.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  taxDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await tax.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ############################################################
  // -------------------    PRODUCT CATEGORY  -------------------
  // ############################################################
  getCategoriesNew({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productcategory.getCategories(payload);
        // (data);
        commit("GET_PRODUCT_CATEGORY_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSingleCategories({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productcategory.getSingleCategories(payload);
        // (data);
        // commit("GET_PRODUCT_CATEGORY_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  categoryAddCollection({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productcategory.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  categoryDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productcategory.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editCategory({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productcategory.editCategory(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // ------------------    PRODUCT SUBCATEGORY  -----------------
  // ############################################################
  getProductSubCategories({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productSubCategory.getSubcategory(payload);
        commit("GET_SUBCATEGORY_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getProductSingleSubCategories({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productSubCategory.getSingleSubcategory(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getProductSubCategoriesById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productSubCategory.getSubcategoryById(payload);
        commit("SELECT_PRODUCT_SUBCATEGORY_BY_ID", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getProductSubCategoriesByIds({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productSubCategory.getSubcategoryByIds(payload);
        commit("SELECT_PRODUCT_SUBCATEGORY_BY_IDS", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  ProductsubCategoryAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productSubCategory.addCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  ProductsubCategoryEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productSubCategory.updateCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  ProductsubCategoryDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productSubCategory.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ############################################################
  // -------------------    PRODUCTS  -----------------------------
  // ############################################################
  getProducts({ commit }, payload = "") {
    return new Promise(async (resolve, reject) => {
      try {
        // const { data } = await product.getAllCollections(payload);
        const { data } = await product.getAllAdminCollections(payload);
        commit("UPDATE_PRODUCTS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getMainAllCollection({ commit }, payload) {
    return new Promise(async (reslove, reject) => {
      try {
        const { data } = await product.getMainAllCollection(payload);
        commit("UPDATE_MAIN_All_PRODUCT_INFO", data.data);
        reslove(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  enqProduct({ commit }, payload) {
    return new Promise(async (reslove, reject) => {
      try {
        const { data } = await Enquire.add(payload);
        commit("ENQUIRE_ADD", data.data);
        reslove(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getProductDetails({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.getOneCollection(payload);
        commit("UPDATE_SINGLE_PRODUCT_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  filterByCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.filterByCategory(payload);
        commit("GET_PRODUCT_BY_CATEGORY", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  filterBySubCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.filterBySubCategory(payload);
        commit("GET_PRODUCT_BY_SUBCATEGORY", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  filterByStatus({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.filterByStatus(payload);
        commit("GET_PRODUCT_BY_STATUS", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  productAddCollection({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  productEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  productDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ############################################################
  // -------------------    COLOR VARIANTSSS -----------------------------
  // ############################################################

  getColorVariant({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await colorVariant.getAllCollection();
        commit("UPDATE_COLORVARIANTS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getPoductsColorVariants({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await colorVariant.getPoductsColorVariants(payload);
        commit("UPDATE_COLORVARIANTS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  colorVariantAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await colorVariant.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  colorVarianttEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await colorVariant.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  colorVariantDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await colorVariant.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    SIZE VARIANTSSS -----------------------------
  // ############################################################
  getSizeVariant({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sizeVariant.getAllCollection();
        commit("UPDATE_SIZEVARIANTS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getPoductsSizeVariants({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sizeVariant.getPoductsSizeVariants(payload);
        commit("UPDATE_SIZEVARIANTS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  similarProductCollection({ commit }, paylaod) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sizeVariant.similarProductCollection();
        // (data);
        commit("UPDATE_SIMILAR_PRODUCTS", data.data); // all info with builin updated MRP, colorVariants,TaxDetails etc
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  },
  getAllPopulatedDetails({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sizeVariant.getAllPopulatedDetails();
        commit("UPDATE_ALL_INFO_PRODUCTS", data.data); // all info with builin updated MRP, colorVariants,TaxDetails etc
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  sizeVariantAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sizeVariant.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  sizeVarianttEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // (payload);
        const { data } = await sizeVariant.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  sizeVariantDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await sizeVariant.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    CARTT -----------------------------
  // ############################################################
  getCart({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.getAllCollections();
        commit("UPDATE_CARTS_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  cartAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  cartEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  cartDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  cartDeleteAll({}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cart.deleteAll();
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    ORDERSS -----------------------------
  // ############################################################
  getOrders({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.getAllCollections();
        commit("UPDATE_ORDERS_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  orderAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  orderEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  orderDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteDispatchFromOneCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await order.deleteDispatchFromOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    Coupons  -----------------------------
  // ############################################################
  getCoupon({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.getAllCollection();
        commit("UPDATE_COUPONS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  couponAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  couponEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  couponDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await coupon.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    DISPATCHERS  -----------------------------
  // ############################################################
  getDispatches({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await dispatch.getAllCollections();
        commit("UPDATE_DISPATCH_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  dispatchAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await dispatch.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  dispatchEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await dispatch.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  dispatchDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await dispatch.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    Address  -----------------------------
  // ############################################################
  getAddresses({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await address.getAllCollection();
        commit("UPDATE_ADDRESS_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getOneAddressesByUser({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await address.getOneCollectionByUser();
        commit("UPDATE_ADDRESS_INFO", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  AddressAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await address.addOneCollection(payload);
        // Set accessToken
        // localStorage.setItem("token", data.data.token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  AddressEditCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await address.editOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  AddressDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await address.deleteOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // ############################################################
  // -------------------    Banners  -----------------------------
  // ############################################################
  BannerGetCollection({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await banner.getBanner();

        commit("BANNER_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  BannerAddCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // (payload);
        const { data } = await banner.addBanner(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  BannerEdiCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await banner.editBanner(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  BannerDeleteCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await banner.deleteCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    Eurobond Reward  -----------------------------
  // ############################################################
  sendEuroReward({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await EuroReward.add(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // ############################################################
  // -------------------    Design Support  -----------------------------
  // ############################################################
  sendDesignSupport({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await DesignSupport.add(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addCareer({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Careers.add(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
