import Repository from "./Repository";

const ADD_ENTRY = "add";

export default {
  add(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/getintouch/${route}`;
};
