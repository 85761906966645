export default {
  // Home Page
  categories: [],
  subcategories: [],
  documents: [],
  updatedocuments: [],
  downloaddocument: {},
  updatesubcategories: [],
  subcategoriesid: [],
  bannerState: [],
  productState: [],
  mainProductState: [],
  singleProductAdminState: [],
  //
  colorVariantState: [],
  sizeVariantState: [],
  taxState: [],
  singleProductState: [],
  singleProductStatee: [],
  //
  cartState: [],
  orderState: [],
  couponState: [],
  productFilterCats: [],
  dispatchState: [],
  addressState: [],
  allDetailsOfProduct: [],
  similarProducts: [],
  // Otheres:
  productData: [],
  productCategory: [],
  productCategorybyId: [],
  productsubCategorybyId: [],
  productsubCategorybyIds: [],
  productStateId: [],
  productbycategory: [],
  productbysubcategory: [],
  productbystatus: [],
  selectproductsubCategorybyIds: [],
  productoverview: [],
  enqprd: [],
  //catalogue
  getcatalogue: [],
  selectcataloguebyid: [],
  updatecatalogue: [],
  getmanycatalogue: [],
  downloadcatalogue: [],

  //contact
  updatecontact: [],
  getcontact: [],
  getsinglecontact:{},

  //news

  getnews: [],

  //event

  getevent: [],
  geteventsingle: [],
  pastevent: [],
  upevent: [],
  updateevent: [],
  uploadimages: [],
  //project

  getprojects: {},
  getprojectcat: [],
  projectid: [],
  singleproject: [],
  singleprojectget: [],
  addproductdetailid: [],
  updateproject: [],

  //blogs

  blogs: [],
  updateblogs: [],
  blogdetails: [],
  blogurl: "",
  singleblog: [],

  //blog category

  blogcategory: [],
  updateblogcategory: [],

  //country

  getcountry: [],

  // states

  getstate: [],
  // city

  getcity: [],

  //banner

  banner: [],
};
