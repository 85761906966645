import API from "../../apis";
const project = API.Project;

export default {
  getProjectDetails({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await project.getProjectDetails();
        commit("SET_PROJECT", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
