<template>
  <div
    id="g-recaptcha"
    class="g-recaptcha"
    :loadRecaptchaScript="true"
    :data-sitekey="$captchaKEY"
    :data-callback="reCapFunction"
    ref="reCaptchaRef"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      // sitekey: this.$recaptchaKEY,

      widgetId: 0,
    };
  },
  methods: {
    reCapFunction(res) {
      this.$emit("verify", res);
    },

    // render() {
    //   if (window.grecaptcha) {
    //     this.widgetId = window.grecaptcha.render("g-recaptcha", {
    //       sitekey: this.$captchaKEY,

    //       // the callback executed when the user solve the recaptcha
    //       callback: (response) => {
    //         // emit an event called verify with the response as payload
    //         this.$emit("verify", response);

    //         // reset the recaptcha widget so you can execute it again
    //       },
    //     });

    //   }
    // },
    render() {
      if (!this.$refs.reCaptchaRef.innerHTML.trim()) {
        if(typeof window.recaptcha!='undefined'){
          window.grecaptcha.render(this.$refs.reCaptchaRef, {
          sitekey: this.$captchaKEY,
          callback: (response) => {
            this.$emit("verify", response);
          },
        });
        }
      }
    },
    reset(){
      this.render()
    }
  },


  mounted() {
    // render the recaptcha widget when the component is mounted
    this.render();
  },
};
</script>
