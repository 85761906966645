import Repository from "./Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";
const SINGLE ="single"

export default {
  addBlog(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editBlog(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteBlog(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getBLog(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
  getSingleBLog(payload) {
    return Repository.post(transformRoute(SINGLE), payload);
  },
};

const transformRoute = (route) => {
  return `/blog/${route}`;
};
