import Repository from "./Repository";

const GET_COUNTRY = "getCountry";
const GET_STATE = "getState";
const GET_CITY = "getCity";

export default {
  getCountry(payload) {
    return Repository.post(transformRoute(GET_COUNTRY), payload);
  },
  getState(payload) {
    return Repository.post(transformRoute(GET_STATE), payload);
  },
  getCity(payload) {
    return Repository.post(transformRoute(GET_CITY), payload);
  },
};

const transformRoute = (route) => {
  return `/countryStateCity/${route}`;
};
