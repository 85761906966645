import Repository from "./Repository";

const ADD_ENTRY = "addProject";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "getProject";
const UPLOAD_ENTRY = "images";
const ADD_PRD_ENTRY = "addProductDetail";
const SINGLE = "getSingleProject";

export default {
  addProject(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editProject(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteProject(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getProject(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
  getSingleProject(payload) {
    return Repository.post(transformRoute(SINGLE), payload);
  },
  uploadImage(payload) {
    return Repository.post(transformRoute(UPLOAD_ENTRY), payload);
  },
  addproductdetails(payload) {
    return Repository.post(transformRoute(ADD_PRD_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/euro_project/${route}`;
};
