import RepositoryFactory from "../../apis/index";
const menu = RepositoryFactory.menu;

export default {
  getAllClientMenu({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await menu.getAllClientMenu(payload);

        commit("ADD_MENU", data.data);
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
  addOneMenu({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await menu.addOneMenu(payload);
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },
};
