<template>
  <div class="w-full">
    <div class="">
      <el-form
        ref="ruleForms"
        :model="ruleForm"
        :rules="rules"
        class="subscribrpop"
      >
        <div class="flex mb-1 ml-1 gap-5">
          <div class="w-full">
            <p class="mb-1 font-semibold text-black fsize12 sm-fsize11">
              Name:
            </p>
            <el-form-item prop="name">
              <el-input placeholder="Enter your name" v-model="ruleForm.name" />
            </el-form-item>
          </div>

          <div class="w-full">
            <el-form-item type="small" prop="iam">
              <p class="mb-1 font-semibold text-black fsize12 sm-fsize11">
                I Am
              </p>
              <el-select
                v-model="ruleForm.iam"
                placeholder="I am"
                class="mb-0 w-full"
              >
                <el-option
                  v-for="item in optionsiam"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <div class="flex mb-1 ml-1 gap-5 mt-2">
          <div class="w-full">
            <p class="mb-1 font-semibold text-black fsize12 sm-fsize11">
              Contact:
            </p>
            <el-form-item prop="mobile">
              <el-input
                placeholder="Mobile number"
                maxlength="10"
                v-model.trim="ruleForm.mobile"
              />
            </el-form-item>
          </div>
          <div class="w-full">
            <p class="mb-1 font-semibold text-black fsize12 sm-fsize11">
              Email id:
            </p>
            <el-form-item prop="email">
              <el-input placeholder="Email id" v-model.trim="ruleForm.email" />
            </el-form-item>
          </div>
        </div>
      </el-form>

      <div class="w-full lg:flex md:flex justify-center gap-2 items-center">
        <!-- <b-button
          @click="handleClose"
       
          class="bg-ff9137hover border rounded-sm text-black px-4 lg:py-2 md:py-4 py-2"
          >Not Now</b-button
        > -->
        <!-- <div class="mt-5">
          <recaptcha
            class="w-full flex justify-center"
            ref="reCaptcha"
            @verify="isVerifyCap"
            @reset="isReset"
          ></recaptcha>
        </div> -->
        <div class="lg:flex md:flex justify-center">
          <div class="lg:mt-0 md:mt-0 mt-5 flex justify-center">
            <div class="nl-btn">
              <vs-button
                @click="handleSubmit('ruleForms')"
                id="newsletterformsubmition"
                class="bg-ff9137 rounded-sm text-white px-6 lg:py-3 md:py-4 py-3"
                >Subscribe</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Recaptcha from "@/views/login/recaptcha.vue";
export default {
  props: {
    ip: { type: String },
  },
  components: {
    Recaptcha,
  },
  data() {
    return {
      captcha: false,
      ruleForm: {
        name: "",
        iam: "",
        mobile: "",
        email: "",
      },
      optionsiam: [
        {
          value: "Architect",
          label: "Architect",
        },
        {
          value: "Interior Designer",
          label: "Interior Designer",
        },
        {
          value: "Fabricator",
          label: "Fabricator",
        },
        {
          value: "Builder",
          label: "Builder",
        },

        {
          value: "Distributor",
          label: "Distributor",
        },
        {
          value: "Consultant",
          label: "Consultant",
        },
        {
          value: "Dealer",
          label: "Dealer",
        },
        {
          value: "User",
          label: "User",
        },
        {
          value: "Other",
          label: "Other",
        },
      ],
      value1: "",
      rules: {
        iam: [
          {
            required: true,
            message: "Please select value",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "Please enter your name",
            trigger: "change",
          },
          {
            pattern: /^[a-zA-Z]+(?: [a-zA-Z]+){0,2}$/,
            message: "Please enter valid name",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "Please enter email address",
            trigger: "change",
          },
          {
            type: "email",
            message: "Please enter correct email address",
            trigger: ["blur", "change"],
          },
        ],
        mobile: [
          {
            required: true,
            messsage: "Please enter mobile number",
            max: 10,
            min: 10,
            trigger: "change",
          },

          {
            pattern: /^\d*$/,
            message: "Mobile must be a number",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    isVerifyCap(res) {
      if (res) {
        this.captcha = true;
      }
    },
    async handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if (this.captcha) {
          //   this.submitNow();
          // } else {
          //   this.$message({
          //     message: "Please verify captcha!",
          //     type: "error",
          //   });
          //   return false;
          // }
          this.submitNow();
        } else {
          return false;
        }
      });
    },
    reCaptchaVerify(res) {
      console.log(res);
    },

    async submitNow() {
      this.sendConversionEvent();
      let payload = {
        name: this.ruleForm.name,
        iam: this.ruleForm.iam,
        mobile: this.ruleForm.mobile,
        email: this.ruleForm.email,
        ip: this.ip,
      };
      if (this.$route.query.hasOwnProperty("utm_campaign")) {
        Object.assign(payload, {
          UTM_Campaign: this.$route.query.utm_campaign,
        });
      } else {
        Object.assign(payload, { UTM_Campaign: "none" });
      }

      if (this.$route.query.hasOwnProperty("utm_medium")) {
        Object.assign(payload, {
          UTM_Medium: this.$route.query.utm_medium,
        });
      } else {
        Object.assign(payload, { UTM_Medium: "none" });
      }

      if (this.$route.query.hasOwnProperty("utm_source")) {
        Object.assign(payload, {
          UTM_Source: this.$route.query.utm_source,
        });
      } else {
        Object.assign(payload, { UTM_Source: "none" });
      }
      try {
        const state = await this.$store.dispatch(
          "admin/addSubscriber",
          payload
        );
        if (state.code == 200) {
          this.$vs.loading.close();
          this.$emit("closeModal2", true);
          this.$refs["ruleForms"].resetFields();
          this.$router.push({ name: "newsletter/thankyou" });
        }
      } catch (error) {
        this.error(error);

        this.$message({
          type: "error",
          message: error,
        });
        this.$vs.loading.close();
      }
    },
    handleClose() {
      this.$emit("closeModal", true);
    },
    async sendConversionEvent() {
      const tagScript = document.createElement("script");
      tagScript.innerText = `gtag('event','conversion',{'send_to':'AW-800891346/VeGaCKGDq8lYENLD8v0C'})`;
      document.head.appendChild(tagScript);
      if (typeof window.gtag === "function") {
        window.gtag("event", "conversion", {
          send_to: "AW-800891346/VeGaCKGDq8lYENLD8v0C",
        });
      }
    },
  },
  mounted() {
    // console.log("nice");
  },
};
</script>

<style>
.bg-ff9137hover:hover {
  background: #ff9137 !important;
  color: #ffff;
}

.subscribrpop .el-form-item {
  margin-bottom: 10px !important;
}
.subscribrpop::el-dialog__header {
  background: grey;
}
.nl-btn {
  display: grid;
  place-content: center;
}

.el-form-item__content {
  line-height: 18px;
}
</style>
