import API from "../../apis";

export default {
  getCountry({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.CountryCityState.getCountry(payload);
        commit("GET_COUNTRY", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getState({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.CountryCityState.getState(payload);
        commit("GET_STATE", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getCity({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.CountryCityState.getCity(payload);
        commit("GET_CITY", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
