import axios from "axios";

// You can use your own logic to set your local or production domain

const baseDomain = process.env.VUE_APP_BASE_URL;
const baseURL = `${baseDomain}`;

const userInfoLocalStorage = localStorage.getItem("token");

const API = axios.create({
  baseURL: baseURL,
  headers: {
    "community-name": process.env.VUE_APP_COMMUNITY_NAME,
    // "Accept-Encoding":'gzip',
  },
});
const token = localStorage.getItem("token");
API.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

export default API;
