import Repository from "./Repository";

const ADD_ENTRY = "addproduct";
const DELETE_ENTRY = "deleteproduct";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";
const GET_ADMIN_PRODUCTS = "getProductsAndVariantsAdmin";
const GET_ONE_ENTRY = "getSingle"; 
const FILTER_BY_CATEGORY = "filterByCategory";
const FILTER_BY_SUBCATEGORY = "getproductbysubcatid";
const FILTER_BY_STATUS="getproductbystatus";
const MAIN_GET = "mainGET";

export default {
  getAllCollections(payload) {
    // (payload);
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
  getAllAdminCollections(payload) {
    // (payload);
    return Repository.post(transformRoute(GET_ADMIN_PRODUCTS), payload);
  },
  getMainAllCollection(payload) {
    return Repository.post(transformRoute(MAIN_GET), payload);
  },
  getOneCollection(payload) {
    return Repository.post(transformRoute(GET_ONE_ENTRY), payload);
  },
  filterByCategory(payload) {
    return Repository.post(transformRoute(FILTER_BY_CATEGORY), payload);
  },
  filterBySubCategory(payload) {
    return Repository.post(transformRoute(FILTER_BY_SUBCATEGORY), payload);
  },
  filterByStatus(payload) {
    return Repository.post(transformRoute(FILTER_BY_STATUS), payload);
  },
  addOneCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editOneCollection(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteOneCollection(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/product/${route}`;
};
