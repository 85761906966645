import Repository from "./Repository";

const ADD_ENTRY = "addcity";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "getcity";

export default {
  addCity(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editCity(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteCity(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getCity(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/city/${route}`;
};
