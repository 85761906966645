import API from "../../apis";
import vueCookies from "vue-cookies";
const brandProfile = API.brandProfile;
export default {
  createBrandProfile({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await brandProfile.createBrandProfile(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  uploadBrandProfile({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await brandProfile.uploadBrandProfile(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getBusinessSuites({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await brandProfile.getBusinessSuites(payload);
        commit("GET_BUSINESS_SUITES", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getBrandProfile({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await brandProfile.getBrandProfile(payload);
        vueCookies.set("primaryLogo", data.data[0].primaryLogo);
        vueCookies.set("primaryColor", data.data[0].primaryColor);
        // console.log(data.data[0]);
        commit("GET_BRAND_PROFILE", data.data[0]);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getSuites({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await brandProfile.getSuites(payload);
        // console.log(data, "Hello");
        commit("GET_SUITES", data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
