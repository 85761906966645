import Repository from "./Repository";

const ADD_ENTRY = "add";
const GET_ALL ="get"

export default {
  add(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  get(payload) {
    return Repository.post(transformRoute(GET_ALL), payload);
  },
  
};

const transformRoute = (route) => {
  return `/subscriber/${route}`;
};
