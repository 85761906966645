<template>
  <div class="p-4">

      <div class="text-center break-normal">
        <h1 v-if="message">{{ message }},​</h1>
        <h1 v-if="subMsg">{{ subMsg }}</h1>
      </div>
   
  </div>
</template>

<script>
export default {
  props: {
    message: { type: String },
    subMsg: { type: String },
  },
  data() {
    return {
      handleSubmit() {
        this.$emit("closeModal3", true);
      },
    };
  },
  methods: {},
};
</script>

<style>
.bg-053c6d {
  background: #053c6d;
}
.btn-secondary:hover {
  background-color: none !important;
}
</style>
