/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
// import SecureLS from "secure-ls";
import SecureLS from "@/utils/secure-ls";
var ls = new SecureLS({ isCompression: false, UseSessionStorage: true });

Vue.use(Vuex);

import moduleAuth from "./auth/index";
import moduleUI from "./UI/index";
import moduleAdmin from "./Admin/index";
import moduleCategory from "./Category/index";
import download from "./download";
import cms from "./cms/index";
import brandProfile from "./brandProfile";
import state from "./state";
import countryStateCity from "./CountryStateCity/index";

import Project from "./project";
import menu from "./CmsMenu/Index";
export default new Vuex.Store({
  modules: {
    mutations: {
      ...{
        UPDATE_PRIMARY_COLOR(state, val) {
          state.themePrimaryColor = val;
        },
      },

      ...{
        UPDATE_SECONDARY_COLOR(state, val) {
          state.themeSecondaryColor = val;
        },
      },
      RESET_STATE(state) {
        Object.keys(initialState).forEach((element, index) => {
          console.log(
            state[element],
            element,
            initialState[element].state,
            "STATE"
          );
          Object.assign(state[element], initialState[element].state);
          // state[element] = state.initialState[element];
        });
      },
    },
    state,
    auth: moduleAuth,
    UI: moduleUI,
    admin: moduleAdmin,
    category: moduleCategory,
    download: download,
    cms: cms,
    brandProfile,
    Project,
    menu,
    countryStateCity,
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [
    createPersistedState({
      paths: ["auth", "cms"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

// window.ls = ls;

// export { ls };
