import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import axios from "axios";

// import { lazyLoadComponentIfVisible } from "./Lazyutils";
// const componentLoader = () => import("@/main/Main.vue");

// const LoadingComponent = () => import("@/router/loading.vue");

// const ErrorComponent = { template: "<div>Error loading component.</div>" };
function setHeader(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const newTitle = doc.head.querySelector("title").innerText;
  const newMetaDesc = doc.head.querySelector('meta[name="description"]');
  const newMetaImage = doc.head.querySelector('meta[property="og:image"]');
  const newMetaImg = doc.head.querySelector('meta[name="image"]');
  const newMetaOGDesc = doc.head.querySelector(
    'meta[property="og:description"]'
  );
  const newMetaTitle = doc.head.querySelector('meta[property="og:title"]');

  // Update document title
  document.title = newTitle;

  // Update meta description if exists
  if (newMetaDesc) {
    // console.log(newMeta, "NEWWWWWWWWWWWWWW");
    const existingMeta = document.querySelector('meta[name="description"]');
    if (existingMeta) {
      existingMeta.setAttribute("content", newMetaDesc.getAttribute("content"));
      existingMeta.setAttribute(
        "content",
        newMetaImage.getAttribute("content")
      );
      existingMeta.setAttribute(
        "content",
        newMetaOGDesc.getAttribute("content")
      );
      existingMeta.setAttribute(
        "content",
        newMetaTitle.getAttribute("content")
      );
      if (newMetaImg) {
        existingMeta.setAttribute(
          "content",
          newMetaImg.getAttribute("content")
        );
      }
    } else {
      const meta = document.createElement("meta");
      meta.setAttribute("name", "description");
      meta.setAttribute("content", newMeta.getAttribute("content"));
      document.head.appendChild(meta);
    }
  }
}
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "*",
      component: () => import("@/views/home/components/ErrorPage.vue"),
    },
    {
      path: "/",
      name: "main",
      component: () => import("@/main/Main.vue"),
      redirect: "home",
      children: [
        {
          path: "/",
          name: "Home",

          component: () => import("@/views/home/Home.vue"),
        },
        {
          path: "home",
          name: "Home",
          component: () => import("@/views/home/Home.vue"),
        },
        {
          path: "index.html",
          name: "index.html",
          component: () => import("@/views/home/Home.vue"),
        },

        {
          path: "newsletter/thankyou",
          name: "newsletter/thankyou",
          component: () => import("@/views/home/Home.vue"),
        },
        {
          path: "footer/thankyou",
          name: "footer/thankyou",
          component: () => import("@/views/home/Home.vue"),
        },

        {
          path: "upcomingpage",
          name: "upcomingpage",
          component: () => import("@/views/home/components/UpcomingPage.vue"),
        },
        {
          path: "product",
          name: "Product",
          component: () => import("@/views/product/Product.vue"),
        },
        {
          path: "Product",
          name: "Product",
          redirect: "product",
          component: () => import("@/views/product/Product.vue"),
        },
        // {
        //   path: "products",
        //   name: "Products",
        //   component: () => import("@/views/product/Product.vue"),
        // },
        {
          path: "Product-Design",
          name: "Product Design",
          redirect: "product-designs",
        },
        {
          path: "product-designs",
          name: "product-designs",
          component: () => import("@/views/categories/Categories.vue"),
        },
        {
          path: "mirror.html",
          redirect: "product-designs",
        },
        {
          path: "metallic.html",
          redirect: "product-designs",
        },
        {
          path: "marble.html",
          redirect: "product-designs",
        },
        {
          path: "wood.html",
          redirect: "product-designs",
        },
        {
          path: "gloss.html",
          redirect: "product-designs",
        },
        {
          path: ":cat/:series/:prd",
          name: "product-overview",
          component: () => import("@/views/overview/Overview.vue"),
        },
        {
          path: "product/:cat/:series/:prd",
          name: "product-overview",
          redirect: ":cat/:series/:prd",
        },
        {
          path: ":cat/:series/:prd/thankyou",
          name: "product-overview/thankyou",
          component: () => import("@/views/overview/Overview.vue"),
        },
        {
          path: "Eurocomb",
          name: "Eurocomb",
          redirect: "eurocomb-aluminium-honeycomb-panels",
        },
        {
          path: "eurocomb-aluminium-honeycomb-panels",
          name: "honey-pannel",
          component: () => import("@/views/honey-pannel/Pannel.vue"),
        },
        {
          path: "honey-pannel",
          name: "honey-pannel",
          redirect: "eurocomb-aluminium-honeycomb-panels",
          component: () => import("@/views/honey-pannel/Pannel.vue"),
        },
        {
          path: "aluminium-honeycomb-panels",
          redirect: "eurocomb-aluminium-honeycomb-panels",
          name: "honey-pannel",
          component: () => import("@/views/honey-pannel/Pannel.vue"),
        },
        {
          path: "about-us",
          name: "about-us",
          redirect: "about-us-eurobond-journey",
          component: () => import("@/views/our-journey/OurJourney.vue"),
        },
        {
          path: "about-us.html",

          redirect: "about-us-eurobond-journey",
        },
        {
          path: "Aboutus-Our-Reach",
          name: "Aboutus-Our-Reach",
          redirect: "global-reach",
          component: () => import("@/views/reach/Reach.vue"),
        },
        {
          path: "Our-Presence",
          name: "Our Presence",
          redirect: "global-reach",
        },
        {
          path: "global-reach",
          name: "Aboutus-Our-Reach",
          component: () => import("@/views/reach/Reach.vue"),
        },
        {
          path: "Aboutus-kmp",
          name: "Aboutus-kmp",
          redirect: "key-managerial-personnel",
          component: () => import("@/views/kmp/Kmp.vue"),
        },
        {
          path: "chairman-message",
          name: "Aboutus-kmp",
          redirect: "key-managerial-personnel",
          component: () => import("@/views/kmp/Kmp.vue"),
        },
        {
          path: "chairman-message.html",

          redirect: "key-managerial-personnel",
        },
        {
          path: "key-managerial-personnel",
          name: "Aboutus-kmp",
          component: () => import("@/views/kmp/Kmp.vue"),
        },
        {
          path: "Key-Managerial-Personnel",
          name: "Key Managerial Personnel",
          redirect: "key-managerial-personnel",
        },
        {
          path: "Aboutus-Our-Manufacturing",
          name: "Aboutus-Our-Manufacturing",
          redirect: "manufacturing-infrastructure",
          component: () => import("@/views/manufacturing/Manufacturing.vue"),
        },
        {
          path: "Our-Manufacturing",
          name: "Our Manufacturing",
          redirect: "manufacturing-infrastructure",
        },
        {
          path: "manufacturing-infrastructure",
          name: "Aboutus-Our-Manufacturing",
          component: () => import("@/views/manufacturing/Manufacturing.vue"),
        },
        {
          path: "infrastructure",
          redirect: "manufacturing-infrastructure",
          name: "Aboutus-Our-Manufacturing",
          component: () => import("@/views/manufacturing/Manufacturing.vue"),
        },
        {
          path: "infrastructure.html",
          redirect: "manufacturing-infrastructure",
        },
        {
          path: "Projects",
          name: "Projects",
          redirect: "acp-commercial-projects",
        },

        {
          path: "acp-commercial-projects",
          name: "project",
          component: () => import("@/views/project/Project.vue"),
        },
        {
          path: "projects",
          redirect: "acp-commercial-projects",
          name: "project",
          component: () => import("@/views/project/Project.vue"),
        },
        {
          path: "project",
          redirect: "acp-commercial-projects",
          name: "project",
          component: () => import("@/views/project/Project.vue"),
        },
        {
          path: "projects.html",
          redirect: "acp-commercial-projects",
        },
        {
          path: "project-overview/:prj",
          name: "project-overview",
          component: () =>
            import("@/views/projectoverview/ProjectOverview.vue"),
        },
        {
          path: "news-events",
          redirect: "events-and-exhibitions",
          name: "events",
          component: () => import("@/views/events/Events.vue"),
        },
        {
          path: "News&Events",
          name: "News & Events",
          redirect: "events-and-exhibitions",
        },
        {
          path: "events",
          redirect: "events-and-exhibitions",
          name: "events",
          component: () => import("@/views/events/Events.vue"),
        },

        {
          path: "events-and-exhibitions",
          name: "events",
          component: () => import("@/views/events/Events.vue"),
        },
        {
          path: "events-and-exhibitions/event/thankyou",
          name: "events/thankyou",
          component: () => import("@/views/events/Events.vue"),
        },

        {
          path: "latests-events/:tag",
          name: "latests-events",
          component: () => import("@/views/latestevents/LatestsEvent.vue"),
        },
        {
          path: "upcoming-events/:tag",
          name: "upcoming-events",
          component: () =>
            import("@/views/upcomingeventpop/UpcomingEventpop.vue"),
          // component: () => import("@/views/home/components/UpcomingPage.vue"),
        },
        {
          path: "upcoming-events/:tag/:id/event/thankyou",
          name: "upcoming-events/thankyou",
          component: () =>
            import("@/views/upcomingeventpop/UpcomingEventpop.vue"),
          // component: () => import("@/views/home/components/UpcomingPage.vue"),
        },
        // {
        //   path: "latests-events",
        //   name: "latests-events",
        //   component: () => import("@/views/latestevents/LatestsEvent.vue"),
        // },
        //  {
        //   path: "upcoming-events",
        //   name: "upcoming-events",
        //   component: () => import("@/views/upcomingeventpop/UpcomingEventpop.vue"),
        // },
        // {
        //   path: "blog-details",
        //   name: "blog-details",
        //   component: () => import("@/views/blogdetails/BlogDetails.vue"),
        // },
        {
          path: "blog",
          redirect: "blogs",
          name: "blogs",
          component: () => import("@/views/blogs/Blogs.vue"),
        },
        {
          path: "blog/",
          redirect: "blogs",
          name: "blogs",
          component: () => import("@/views/blogs/Blogs.vue"),
        },
        // {
        //   path: "catelogue",
        //   name: "catelogue",
        //   component: () => import("@/views/downloadcatelogue/DownloadCatelogue.vue"),
        // },
        {
          path: "rewards",
          name: "rewards",
          redirect: "https://rewards.eurobondacp.com",
        },
        {
          path: "About-Eurobond",
          name: "About Eurobond",
          redirect: "about-us-eurobond-journey",
        },
        {
          path: "Aboutus-Our-Journey",
          name: "Aboutus-Our-Journey",
          redirect: "about-us-eurobond-journey",

          component: () => import("@/views/our-journey/OurJourney.vue"),
        },
        {
          path: "about-us-eurobond-journey",
          name: "Aboutus-Our-Journey",

          component: () => import("@/views/our-journey/OurJourney.vue"),
        },
        {
          path: "fr-products",
          redirect: "fire-retardant-panels",
          name: "fr-acp",
          component: () => import("@/views/fr-acp/FrAcp.vue"),
        },
        {
          path: "why-fr",
          redirect: "fire-retardant-panels",
          name: "fr-acp",
          component: () => import("@/views/fr-acp/FrAcp.vue"),
        },
        {
          path: "fr.html",
          redirect: "fire-retardant-panels",
        },
        {
          path: "fire-retardant-panels",
          name: "fr-acp",
          component: () => import("@/views/fr-acp/FrAcp.vue"),
        },
        {
          path: "FR-ACP",
          name: "FR ACP",
          redirect: "fire-retardant-panels",
        },
        {
          path: "fr-acp",
          name: "fr-acp",
          redirect: "fire-retardant-panels",
          component: () => import("@/views/fr-acp/FrAcp.vue"),
        },
        {
          path: "AHPL",
          name: "AHPL",
          redirect: "aluminium-hpl-sheet",
        },
        {
          path: "aluminium-hpl-sheet",
          name: "ahpl",
          component: () => import("@/views/ahpl/Ahpl.vue"),
        },
        {
          path: "ahpl",
          name: "ahpl",
          redirect: "aluminium-hpl-sheet",
          component: () => import("@/views/ahpl/Ahpl.vue"),
        },
        {
          path: "products/aluminium-hpl-sheet",
          name: "products",
          redirect: "aluminium-hpl-sheet",
          component: () => import("@/views/ahpl/Ahpl.vue"),
        },
        {
          path: "ACP",
          name: "ACP",
          redirect: "acp-aluminium-composite-panels",
        },
        {
          path: "acp-aluminium-composite-panels",
          name: "acp1",
          component: () => import("@/views/acp/Acp.vue"),
        },
        {
          path: "acp1",
          name: "acp1",
          redirect: "acp-aluminium-composite-panels",
          component: () => import("@/views/acp/Acp.vue"),
        },
        {
          path: "products/aluminium-composite-panel",
          name: "products",
          redirect: "acp-aluminium-composite-panels",
          component: () => import("@/views/acp/Acp.vue"),
        },
        {
          path: "aluminium-composite-panel",
          redirect: "acp-aluminium-composite-panels",
          name: "acp1",
          component: () => import("@/views/acp/Acp.vue"),
        },
        {
          path: "Eurocore",
          name: "Eurocore",
          redirect: "eurocore-aluminium-core-composite-panel",
        },
        {
          path: "eurocore-aluminium-core-composite-panel",
          name: "eurocore",
          component: () => import("@/views/eurocore/Eurocore.vue"),
        },
        {
          path: "eurocore",
          name: "eurocore",
          redirect: "eurocore-aluminium-core-composite-panel",
          component: () => import("@/views/eurocore/Eurocore.vue"),
        },
        {
          path: "ZINC",
          name: "ZINC",
          redirect: "zinc-panels",
        },
        {
          path: "zinc-panels",
          name: "zinc",
          component: () => import("@/views/zinc/Zinc.vue"),
        },
        {
          redirect: "zinc-panels",
          path: "zinc-panels",
        },
        {
          redirect: "zinc-panels",
          path: "zinc-composite-panels",
        },
        // {
        //   path: "Our-Reach",
        //   name: "reach",
        //   component: () => import("@/views/reach/Reach.vue"),
        // },
        // {
        //   path: "kmp-about",
        //   name: "kmp",
        //   component: () => import("@/views/kmp/Kmp.vue"),
        // },
        // {
        //   path: "Our-Manufacturing",
        //   name: "Manufacturing",
        //   component: () => import("@/views/manufacturing/Manufacturing.vue"),
        // },
        {
          path: "Contact",
          name: "Contact",
          redirect: "store-locator",
        },
        {
          path: "store-locator",
          name: "contact",
          component: () => import("@/views/contact/Contact.vue"),
        },
        {
          path: "store-locator/thankyou",
          name: "store-locator/thankyou",
          component: () => import("@/views/contact/Contact.vue"),
        },
        {
          path: "contact",
          redirect: "store-locator",
          name: "contact",
          component: () => import("@/views/contact/Contact.vue"),
        },
        {
          path: "network.html",
          redirect: "store-locator",
        },
        {
          path: "contact-us",
          redirect: "store-locator",
          name: "contact",
          component: () => import("@/views/contact/Contact.vue"),
        },
        {
          path: "contact-us.html",
          redirect: "store-locator",
        },
        // {
        //   path: "project",
        //   name: "project",
        //   component: () => import("@/views/project/Project.vue"),
        // },
        // {
        //   path: "project-overview/:prj",
        //   name: "project-overview",
        //   component: () =>
        //     import("@/views/projectoverview/ProjectOverview.vue"),
        // },
        {
          path: "Careerss",
          name: "Careers",
          redirect: "careers",
        },
        {
          path: "careers",
          name: "careers",
          component: () => import("@/views/career/Career.vue"),
        },
        {
          path: "careers/thankyou",
          name: "careers/thankyou",
          component: () => import("@/views/career/Career.vue"),
        },

        {
          path: "blogs/:name",
          name: "blog",
          component: () => import("@/views/blogdetails/BlogDetails.vue"),
          beforeEnter: async (to, from, next) => {
            await axios
              .get(`${process.env.VUE_APP_WEB_URL}/blogs/${to.params.name}`)
              .then(async (response) => {
                console.log(response, "llllll");
                next();
                setHeader(response.data);
                let payload = {
                  blogName: to.params.name.replace(/-/g, " "),
                };
                await store.dispatch("admin/getBlogs", payload);
              })
              .catch(async (err) => {
                console.log(err);
                next();
                let payload = {
                  blogName: to.params.name.replace(/-/g, " "),
                };
                await store.dispatch("admin/getBlogs", payload);
              });

            next();
          },
        },
        {
          path: "blogs",
          name: "blogs",
          component: () => import("@/views/blogs/Blogs.vue"),
          // component: () => import("@/views/home/components/UpcomingPage.vue"),
        },
        {
          path: "blog",
          name: "Blogs",
          redirect: "blogs",
        },
        {
          path: "test-and-certificates",
          name: "test-and-certificates",
          redirect: "download-catalogues",
          component: () =>
            import("@/views/downloadcatelogue/DownloadCatelogue.vue"),
        },
        {
          path: "download-catalogues",
          name: "download-catalogues",
          component: () =>
            import("@/views/downloadcatelogue/DownloadCatelogue.vue"),
        },
        {
          path: "download-catalogues/download/thankyou",
          name: "download-catalogues/thankyou",
          component: () =>
            import("@/views/downloadcatelogue/DownloadCatelogue.vue"),
        },
        {
          path: "Investor-Relation",
          name: "Investor Relation",
          redirect: "investor-relations",
        },
        {
          path: "investor-relations",
          name: "investor-relations",
          component: () =>
            import("@/views/investor-relations/InvestorRelations.vue"),
        },
        {
          path: "investor-relation",
          name: "investor-relation",
          redirect: "investor-relations",
          component: () =>
            import("@/views/investor-relations/InvestorRelations.vue"),
        },
        {
          path: "design-support",
          name: "design-support",
          component: () => import("@/views/designsupport/DesignSupport.vue"),
        },
        {
          path: "design-support/thankyou",
          name: "design-support/thankyou",
          component: () => import("@/views/designsupport/DesignSupport.vue"),
        },
        {
          path: "Design-Support",
          name: "Design Support",
          redirect: "design-support",
        },
        {
          path: "facade-preview",
          redirect: "design-support",
          name: "facade-preview",
          component: () => import("@/views/designsupport/DesignSupport.vue"),
        },
        {
          path: "admin-login",
          name: "admin-login",
          component: () => import("@/views/login/Login.vue"),
        },
        {
          path: "distributor",
          name: "distributor",
          component: () =>
            import("@/views/contact/distributor/Distributor.vue"),
        },
      ],
    },

    //dark navbar
    // {
    //   path: "/channel",
    //   name: "maindark",
    //   component: () => import("@/main/MainDark.vue"),
    //   redirect: "channel-discussion",
    //   children: [],
    // },
  ],
});

export default router;
