import Vue from "vue";
import router from "@/router";
import App from "./App.vue";
import "@/assets/vuexy/main.scss";
import "@/assets/scss/Index.scss";
import "@/assets/scss/CMS.scss";
import VuePageTransition from "vue-page-transition";
import VueSocialSharing from "vue-social-sharing";
import vgoogleTranslate from "v-google-translate";
import ElementUI from "element-ui";
import VueMeta from "vue-meta";
import "../themeConfig";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";

locale.use(lang);
Vue.use(ElementUI);
import "element-ui/lib/theme-chalk/index.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
// import "@/assets/scss/argon.scss";
import VueLazyload from "vue-lazyload";
import vueCookies from "vue-cookies";
Vue.use(vueCookies);
Vue.use(vgoogleTranslate);
Vue.use(VueLazyload);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
});
AOS.init();
Vue.use(VuePageTransition);
Vue.use(VueSocialSharing);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin);
import Vuesax from "vuesax";
// import DashboardPlugin from "./plugins/dashboard-plugin";
import FeatherIcon from "@/components/FeatherIcon.vue";
Vue.component(FeatherIcon.name, FeatherIcon);
// Vue.use(DashboardPlugin);
Vue.prototype.$imageURL = process.env.VUE_APP_STORAGE_URL_2;
Vue.prototype.$captchaKEY = process.env.VUE_APP_RECAPTCHA_KEY;
import VueSweetalert2 from "vue-sweetalert2";
require("vue2-animate/dist/vue2-animate.min.css");
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);
// Vue.use(VueMaterial);
// import VueQuillEditor from "vue-quill-editor";

// import "quill/dist/quill.core.css"; // import styles
// import "quill/dist/quill.snow.css"; // for snow theme
// import "quill/dist/quill.bubble.css"; // for bubble theme

// Vue.use(VueQuillEditor /* { default global options } */);
import "vuesax/dist/vuesax.css"; //Vuesax styles
Vue.use(Vuesax, {
  // options here
});
require("vue2-animate/dist/vue2-animate.min.css");
// Vue.use(VueSweetalert2);
import "./assets/scss/tailwind.css";
Vue.config.productionTip = false;
// Vuex Store
import store from "./store/index.js";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.info = () => {};
  console.debug = () => {};
}

window.app = new Vue({
  store,
  router,
  Vuesax,
  render: (h) => h(App),
}).$mount("#app");

import VueHorizontalTimeline from "vue-horizontal-timeline";
// Vue.prototype.$imageURL = process.env.VUE_APP_IMAGE_URL;
Vue.use(VueHorizontalTimeline);
export default {
  components: {
    VueHorizontalTimeline,
  },
};
