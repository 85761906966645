import Repository from "./Repository";

const ADD_ENTRY = "createhomebanner";
const UPDATE_ENTRY = "edithomebanner";
const GET_ENTRY = "gethomebanner";

export default {
  addBanner(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editBanner(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  getBanner(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/banner/${route}`;
};
