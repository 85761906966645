export default {
  GET_COUNTRY(state, payload) {
    state.countryList = payload;
  },
  GET_STATE(state, payload) {
    state.stateList = payload;
  },
  GET_CITY(state, payload) {
    state.cityList = payload;
  },
};
