import Repository from "./Repository";

const ADD_ENTRY = "addcountry";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "getcountry";

export default {
  addCountry(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editCountry(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteCountry(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getCountry(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/country/${route}`;
};
